import Empty from "@/components/Empty";
import Loader from "@/components/Loader";
import albumModel from "@/models/album.model";
import marketModel from "@/models/market.model";
import { useInfiniteScroll, useReactive, useRequest } from "ahooks";
import KeepAlive from "keepalive-for-react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Image, List, PullRefresh, Search, Sticky, Tabs } from "react-vant";
import styles from "./index.module.css";

interface ListViewProps {
  type: string;
  keyword?: string;
  onClick?: (item: any) => void;
}

const ListView = ({ type, keyword, onClick }: ListViewProps) => {
  const { data, loading, loadMoreAsync, reloadAsync, noMore } =
    useInfiniteScroll(
      (d) => {
        return marketModel
          .list({
            albumId: type,
            params: {
              keyword,
              current: d ? d?.current + 1 : 1,
              pageSize: 20,
            },
          })
          .then((res) => res?.data);
      },
      {
        reloadDeps: [type, keyword],
        isNoMore: (d) => (d ? !d?.hasMore : true),
      }
    );
  return (
    <PullRefresh onRefresh={reloadAsync}>
      {loading ? (
        <Loader />
      ) : !data || data?.list?.length <= 0 ? (
        <Empty />
      ) : (
        <List
          className={styles.list}
          finished={noMore}
          finishedText="我是有底线的"
          onLoad={async () => {
            await loadMoreAsync();
          }}
        >
          {data?.list?.map((item: any) => (
            <div
              key={item.id}
              className={styles.item}
              onClick={() => onClick && onClick(item)}
            >
              {item?.num <= 0 && (
                <Image
                  className={styles.delisted}
                  src={"/images/market/delisted.png"}
                />
              )}
              <div className={styles.info}>
                <Image className={styles.image} src={item.image} />
                <div className={styles.circulation}>
                  流通: {item.circulation}
                </div>
              </div>
              <div className={styles.content}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.tags}>
                  <div className={styles.limit}>
                    <span className={styles.label}>限量</span>
                    <span className={styles.num}>{item?.limit}份</span>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <div className={styles.publisher}>
                  限价: ¥{item.highestPrice}
                </div>
                {item?.num > 0 ? (
                  <div className={styles.price}>
                    ￥<span>{item.price}</span>起
                  </div>
                ) : (
                  <div className={styles.price}>退市</div>
                )}
              </div>
            </div>
          ))}
        </List>
      )}
    </PullRefresh>
  );
};

export default () => {
  const nav = useNavigate();
  const state = useReactive({
    tab: "all",
    keyword: "",
  });

  const { data: albums } = useRequest(
    () => {
      return albumModel
        .list({
          params: {
            current: 1,
            pageSize: 100,
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: (res) => {
        if (!!res?.list) {
          state.tab = res?.list[0]?.id;
        }
      },
    }
  );

  const tabs = useMemo(() => {
    return [
      ...(albums
        ? albums?.list?.map((item: any) => {
            return {
              key: item.id,
              title: item.title,
              component: ListView,
              cache: true,
            };
          })
        : []),
    ];
  }, [albums]);

  const page = useMemo(() => {
    return tabs.find((tab) => tab.key == state.tab);
  }, [tabs, state.tab]);

  return (
    <div className={styles.page}>
      <Sticky>
        <Search
          className={styles.search}
          placeholder="搜索资产、盲盒"
          clearable
          leftIcon={
            <Image
              className={styles.icon}
              src={require("@/assets/icons/search.png").default}
            />
          }
          value={state.keyword}
          onSearch={(e) => {
            state.keyword = e;
          }}
        />
        <Tabs
          className={styles.tabs}
          type="capsule"
          align="start"
          active={state.tab}
          onChange={(key) => {
            state.tab = key.toString();
          }}
        >
          {tabs.map((item) => (
            <Tabs.TabPane key={item.key} name={item.key} title={item.title} />
          ))}
        </Tabs>
      </Sticky>
      <KeepAlive
        max={20}
        strategy={"PRE"}
        activeName={state.tab.toString() + state.keyword}
        cache={page?.cache}
      >
        {page && (
          <page.component
            type={page.key}
            keyword={state.keyword}
            onClick={(item: any) => {
              nav(`/market/detail/${item.id}`);
            }}
          />
        )}
      </KeepAlive>
    </div>
  );
};
